var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import TrackShippingDialog from "./TrackShippingDialog.vue";
import moment from "moment";
export default Vue.extend({
    components: {
        TrackShippingDialog: TrackShippingDialog,
    },
    props: {
        purchase: {
            type: Object,
            required: true,
        },
        col: {
            type: Number,
            required: true,
        },
    },
    fragments: {
        purchase: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment PurchaseCard_purchase on Purchase {\n        id\n        displayInvoiceNumber\n        createdAt\n        requiresShipping\n        shippingTrackingInfo\n        invoiceUrl\n        shippingTotal {\n          amount\n          currency\n        }\n        discount {\n          amount\n          currency\n        }\n        taxTotal {\n          amount\n          currency\n        }\n        total {\n          amount\n          currency\n        }\n        lineItems {\n          description\n          price {\n            amount\n            currency\n          }\n        }\n        agentPurchasedOnBehalfEmailAddress\n      }\n    "], ["\n      fragment PurchaseCard_purchase on Purchase {\n        id\n        displayInvoiceNumber\n        createdAt\n        requiresShipping\n        shippingTrackingInfo\n        invoiceUrl\n        shippingTotal {\n          amount\n          currency\n        }\n        discount {\n          amount\n          currency\n        }\n        taxTotal {\n          amount\n          currency\n        }\n        total {\n          amount\n          currency\n        }\n        lineItems {\n          description\n          price {\n            amount\n            currency\n          }\n        }\n        agentPurchasedOnBehalfEmailAddress\n      }\n    "]))),
    },
    data: function () {
        return {
            showTracking: false,
        };
    },
    computed: {
        purchaseTitle: function () {
            return (this.purchase.displayInvoiceNumber +
                " " +
                moment(this.purchase.createdAt).format("DD MMM YYYY"));
        },
    },
    methods: {
        onShowTracking: function () {
            this.showTracking = true;
        },
        onHideTracking: function () {
            this.showTracking = false;
        },
    },
});
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import gql from "graphql-tag";
import PurchaseCard from "./PurchaseCard.vue";
import { appendConnection } from "../../utils/apollo";
import PurchaseCardLoading from "./PurchaseCardLoading.vue";
export default Vue.extend({
    components: {
        PurchaseCard: PurchaseCard,
        PurchaseCardLoading: PurchaseCardLoading,
    },
    apollo: {
        viewerStudent: {
            fetchPolicy: "cache-and-network",
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query PurchasesQuery($after: String) {\n          viewerStudent {\n            id\n            purchases(first: 9, after: $after) {\n              edges {\n                node {\n                  id\n                  ...PurchaseCard_purchase\n                }\n              }\n              pageInfo {\n                endCursor\n                hasNextPage\n              }\n            }\n          }\n        }\n        ", "\n      "], ["\n        query PurchasesQuery($after: String) {\n          viewerStudent {\n            id\n            purchases(first: 9, after: $after) {\n              edges {\n                node {\n                  id\n                  ...PurchaseCard_purchase\n                }\n              }\n              pageInfo {\n                endCursor\n                hasNextPage\n              }\n            }\n          }\n        }\n        ", "\n      "])), PurchaseCard.options.fragments.purchase),
        },
    },
    data: function () {
        return {};
    },
    computed: {
        hasMore: function () {
            return (!!this.viewerStudent &&
                this.viewerStudent.purchases.pageInfo.hasNextPage);
        },
    },
    methods: {
        onLoadMore: function () {
            if (!this.viewerStudent) {
                return;
            }
            this.$apollo.queries.viewerStudent.fetchMore({
                variables: {
                    after: this.viewerStudent.purchases.pageInfo.endCursor,
                },
                updateQuery: function (previousQueryResult, _a) {
                    var fetchMoreResult = _a.fetchMoreResult;
                    return __assign(__assign({}, previousQueryResult), { viewerStudent: __assign(__assign({}, previousQueryResult.viewerStudent), { purchases: appendConnection(previousQueryResult.viewerStudent.purchases, fetchMoreResult.viewerStudent.purchases) }) });
                },
            });
        },
    },
    metaInfo: function () {
        return {
            title: this.$t("Purchases").toString(),
        };
    },
});
var templateObject_1;
